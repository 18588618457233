@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    background: #fff;
    font-family: "Roboto", sans-serif;
    margin: 0;
    /* setting margin/padding to zero makes page touch all borders */
    padding: 0;
    font-size: 1vw;
    line-height: normal;
    color: black;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Poppins", sans-serif;
    color: black;
}

.title-bar {
    display: flex;
    flex-direction: column;
    /* Stack items vertically */
    align-items: flex-start;
    /* Align items to the start (left) */
    justify-content: flex-start;
    /* Align items to the top */
    padding: 10px 10px;
    background-color: rgba(0, 0, 0, 0.3);
}

.logo {
    min-width: 150px;
    width: 8vw;
    height: auto;
    margin-right: auto;
    padding-top: 15px;
    padding-left: 10%;
}

.welcome {
    color: black;
    font-size: calc(1rem + 1vw);
    height: calc(100vh - 160px);
    /* must subtract titlebar height */
}

/* these 2 classes ensure the vert. line has dynamic height */
.welcome-container {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: stretch;
}

.welcome-text {
    padding-left: 15px;
    padding-right: 10%;
}

/* draws the vertical line next to welcome text */
.vertical-line {
    border-left: 2px solid rgba(255, 255, 255, 0.5);
    /* width, must be solid */
}

/* makes the background video appear underneath other content */
/* width, height, top, left... etc. important so video fits screen on all res */
.back-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}



.about-text {
    padding-left: 15%;
    padding-right: 15%;
    font-size: 1.4vw;
}



.our-services, .areas-of-work, .about {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    margin: 0 auto;
    width: 80%;
}

/* making services text and line slightly transparent */
.our-services p, .areas-of-work p {
    color: rgba(255, 255, 255, 0.7);
}

hr {
    border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: rgba(255, 255, 255, 0.7);
    ;
    height: 1px;
}

.title-wrapper {
    text-align: center;
    width: 100%;
    /* makes the <hr> line full width */
}

.columns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0;
}

.column {
    flex: 0 1 calc(33.33% - 100px);
    box-sizing: border-box;
    text-align: center;
}

/* ensures each column is aligned regardless of amount of text */
.column p {
    min-height: 100px;
}

.circle-image {
    width: 15vw;
    height: 15vw;
    min-width: 300px;
    min-height: 300px;
    border-radius: 50%;
    object-fit: cover;
}

/* makes the services columns stack vertically once they can't fit side by side */
@media (max-width: 1200px) {
    .columns {
        flex-direction: column;
    }

    .column {
        flex: 0 1 100%;
        max-width: 300px;
        margin-bottom: 100px;
        /* Add some space below each column */
    }

    body {
        font-size: 18px;
    }

    .circle-image {
        min-width: 250px;
        min-height: 250px;
    }

    .about-text {
        font-size: 20px;
        padding-left: 10%;
        padding-right: 10%;
    }
}

@keyframes fade-in-up {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-fade-in-up {
    animation: fade-in-up 0.8s ease-out forwards;
}

.delay-300 {
    animation-delay: 0.3s;
}

.delay-500 {
    animation-delay: 0.5s;
}

/* 
.navbar a {
  margin: 0 0.5em;
  display: none;
}


.hamburger {
  display: block;
  background: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: black;
} */

/* Hamburger active state */
/* .hamburger.active + .navbar a {
  display: block;
} */

/* Media query for desktop */
/* @media screen and (min-width: 768px) { 
  .navbar {
      display: flex !important;
  }
  .hamburger {
      display: none; /* Hide hamburger on desktop */
/*
  }
  .navbar a {
      display: inline-block !important; /* Ensure links are always displayed on desktop */
/*
  }
} */

.contact-us {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    margin: 0 auto;
    width: 80%;

    text-align: center;
}

a {
    color: black;
}

footer {
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    padding-top: 50px;
    padding-bottom: 20px;
}

p {
    color: black;
}

.affiliates-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    margin: 0 auto;
    width: 80%;

    text-align: center;
}

.affiliates-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* Center items horizontally */
    padding: 0;
    margin: 0;
    align-items: center;
}

.column {
    flex-basis: calc(25% - 10px);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* This centers the content within each column vertically */
    align-items: center;
    /* This centers the content within each column horizontally */
}

.affiliate-image {
    max-width: 100%;
    /* Ensures the image doesn't overflow its container */
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    /* Adds some space between the image and the text below */
}

.affiliate-circle {
    max-width: 100%;
    /* Ensures the image doesn't overflow its container */
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    /* Adds some space between the image and the text below */
    border-radius: 50%;
    object-fit: cover;
}

.affiliates-list .column {
    flex-basis: calc(25% - 20px);
    /* Adjust as needed */
    margin: 10px;
    /* Even margin around each logo */
    max-width: 25%;
}

.affiliates-list li {
    margin: 20px;
    font-size: calc(1rem + 0.5vw);
    color: rgba(255, 255, 255, 0.9);
}

.title-wrapper hr {
    width: 80%;
    /* Adjust to your preferred width */
    margin: 0 auto;
    /* Centers the hr element */
}

/* .navbar {
  display: none !important;
  align-items: center;
  height: 100%;
  margin-left: auto;
  padding: 0;
}

.navbar a:hover {
  color: #E42127; /* Gold color for hover effect
} */

/* Adjust the flex basis and margins for smaller screens */
@media (max-width: 768px) {
    .affiliates-list .column {
        flex-basis: calc(50% - 20px);
        /* Two logos per row */
        margin: 10px 5px;
        max-width: 50%;
    }
}

/* Icon 1 */

.animated-icon1, .animated-icon2, .animated-icon3 {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.animated-icon1 span, .animated-icon2 span, .animated-icon3 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.animated-icon1 span {
    background: #E42127;
}

.animated-icon2 span {
    background: #e3f2fd;
}

.animated-icon3 span {
    background: #f3e5f5;
}

.animated-icon1 span:nth-child(1) {
    top: 0px;
}

.animated-icon1 span:nth-child(2) {
    top: 10px;
}

.animated-icon1 span:nth-child(3) {
    top: 20px;
}

.animated-icon1.open span:nth-child(1) {
    top: 11px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.animated-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.animated-icon1.open span:nth-child(3) {
    top: 11px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

/* Icon 3*/

.animated-icon2 span:nth-child(1) {
    top: 0px;
}

.animated-icon2 span:nth-child(2), .animated-icon2 span:nth-child(3) {
    top: 10px;
}

.animated-icon2 span:nth-child(4) {
    top: 20px;
}

.animated-icon2.open span:nth-child(1) {
    top: 11px;
    width: 0%;
    left: 50%;
}

.animated-icon2.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.animated-icon2.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.animated-icon2.open span:nth-child(4) {
    top: 11px;
    width: 0%;
    left: 50%;
}

/* Icon 4 */

.animated-icon3 span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.animated-icon3 span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.animated-icon3 span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

.animated-icon3.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0px;
    left: 8px;
}

.animated-icon3.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

.animated-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 21px;
    left: 8px;
}

/* .navbar-toggler {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(500%);
} */

.navbar {
    justify-content: space-between;
}

.navbar-brand {
    flex-grow: 1;
}

.about, .our-services, .affiliates-section, .contact-us {
    padding-top: 100px;
    /* Adjust this value based on the height of your navbar */
}

/* Scrollbar */
body::-webkit-scrollbar {
    width: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
body::-webkit-scrollbar-thumb {
    background: linear-gradient(#44ffd1 25%, #5bc3eb 75%);
    border-radius: 10px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#39d4ad 25%, #4ca3c5 75%);
}

/* Scrollbar for textarea */
textarea::-webkit-scrollbar {
    width: 10px;
}

/* Track */
textarea::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 30px;
    margin-bottom: 30px;
}

/* Handle */
textarea::-webkit-scrollbar-thumb {
    background: linear-gradient(#44ffd1 25%, #5bc3eb 75%);
    border-radius: 10px;
}

/* Handle on hover */
textarea::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#39d4ad 25%, #4ca3c5 75%);
}

textarea::-webkit-scrollbar-corner {
    background-color: #5bc3eb;
}

.spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    background: linear-gradient(#39d4ad 25%, #4ca3c5 75%);
    animation: spin 1s linear infinite;
}
  
.spinner::before {
    content: '';
    position: absolute;
    inset: 4px;
    border-radius: 50%;
    background: #071013;
    z-index: 1;
}
  
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  